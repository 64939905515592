<template>
  <div class="Benefit_detail">
    <div class="ben_box" v-for="(item, index) in mouthData" :key="index">
      <div class="zd_title">
        {{ new Date(item.firstDay).getMonth() + 1 }}月账单账单
      </div>
      <div class="box_ls">
        <div class="ls_small" v-if="!isAdvance && !isSpecialAgent && !isSpecialMer">
          <div class="ls_money">
            ¥{{ item.showFakerProfitIncome }}
          </div>
          <div class="ls_ms">门店流水</div>
        </div>
        <div class="ls_small" v-if="searchProfit == 0 && !isAdvance">
          <div class="ls_money">
            {{ isSpecialMer?'98':item.showProfitPercent }}%
          </div>
          <div class="ls_ms">分润比例</div>
        </div>
        <div class="ls_small">
          <div class="ls_money" style="color: #1890ff">
            ¥{{ item.showIncome }}
          </div>
          <div class="ls_ms">我的收益</div>
        </div>
      </div>
      <div class="ben_time">
        <div>账单时间：{{ item.firstDay }} 至 {{ item.lastDay }}</div>
        <div>出账时间：{{ item.createTime.split("T")[0] }}</div>
      </div>
      <div class="ben_bt">详情：</div>
      <div class="xq_box" v-for="(item,index) in dataList" :key="index">
        <div class="xq_top">{{item.incomeDay}}</div>
        <div class="xq_bottom">
          <!-- <div v-if="!isSpecialAgent && !isSpecialMer && !isAdvance">门店流水：¥{{item.profitIncome}}</div> -->
          <div v-if="isAdvance">门店流水：¥{{item.income}}</div>
          <div v-else>我的收益：¥{{item.income}}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      searchProfit: 2,
      mouthData: [],
      dataList: [],
      isAdvance: false,
      isSpecialAgent:false,
      isSpecialMer:false
    };
  },
  mounted() {
    let arr = [];
    arr.push(JSON.parse(localStorage.getItem("benefit")));
    this.mouthData = arr;
    this.searchProfit = this.mouthData[0].searchProfit;
    this.$http.getDetails(null).then((res) => {
      if (res.code == 200) {
        this.isAdvance = res.merchant.isAdvance;
        var agentId = res.merchant.agentId + ''
        if (agentId && this.$http.xxagentArr.indexOf(agentId) != -1) {
          this.isSpecialAgent = true
        }
        var merchantId = res.merchant.merchantId + ''
        if (merchantId && this.$http.xxmerArr.indexOf(merchantId) != -1) {
          this.isSpecialMer = true
        }
      }
    });
    this.$http
      .dayDetail({ month: this.mouthData[0].incomeMonth })
      .then((res) => {
        if (res.code === 200) {
          if (res.data) {
            this.dataList = res.data.reverse();
            this.dataList.forEach((item, index)=>{
              // 显示分润比例
              item.showProfitPercent = Math.floor(item.profitPercent * 100) / 100
                      ? Math.floor(item.profitPercent * 100) / 100 : 0;
              // 显示我的收益
              item.showIncome = item.income ? item.income : 0;
              // 显示门店流水
              item.showProfitIncome = item.profitIncome ? item.profitIncome : 0;
              // 显示计算后的虚假门店流水
              item.showFakerProfitIncome = parseInt(item.showProfitPercent) !== 0
                      ? item.showIncome / item.showProfitPercent * 100 : 0;
              item.showFakerProfitIncome = parseFloat(item.showFakerProfitIncome.toFixed(1));
            });
          }
        }
      });
  },
};
</script>
<style lang="scss" scoped>
.Benefit_detail {
  width: 100%;
  height: 100%;
  background: #f8f8f8;
  overflow-y: auto;
  .ben_box {
    width: 335px;
    background: #ffffff;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    margin: 0 auto;
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    .zd_title {
      height: 52px;
      width: 100%;
      line-height: 52px;
      padding-left: 16px;
      box-sizing: border-box;
      border-bottom: 1px solid #eee;
      font-size: 14px;
      font-weight: bold;
      color: #333333;
    }
    .box_ls {
      width: 100%;
      height: 85px;
      padding: 0 30px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-around;
      .ls_small {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .ls_money {
          font-weight: 600;
          color: #333333;
          font-size: 20px;
        }
        .ls_ms {
          margin-top: 4px;
          font-size: 14px;
          font-weight: 400;
          color: #aaaaaa;
        }
      }
    }
    .ben_time {
      width: 100%;
      height: 59px;
      padding: 0 16px 16px 16px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      font-size: 12px;
      font-weight: 400;
      color: #666666;
    }
    .ben_bt {
      width: 100%;
      box-sizing: border-box;
      font-size: 14px;
      font-weight: bold;
      color: #333333;
      padding-left: 16px;
      margin-top: 8px;
      margin-bottom: 16px;
    }
    .xq_box {
      margin-bottom: 16px;
      height: 40px;
      width: 100%;
      padding: 0 16px;
      box-sizing: border-box;
      /* margin-top: 21px; */
      font-weight: 400;
      color: #666666;
      font-size: 12px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      .xq_bottom {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
}
</style>
